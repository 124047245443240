.quantity-control {
    display: flex;
    align-items: center;
    border: solid 1px #dee2e6;
    border-radius: 0.375rem;
    padding: .375rem .75rem !important;
  }
  
  .quantity-control .btn {
    margin: 0 !important;
    color: white;
    font-weight: bold;
    padding-top: 0;
    padding-bottom: 0;
    width: 10%;
    font-size: 3em !important;
    background-color: #f7b731;
    outline: none;
  }
  
  .quantity-control .btn:hover {
    background-color: #fed330; /* Prevent background color change on hover */
  }

  .quantity-control .btn:focus {
    outline: none;
  }


  .quantity-control .quantity-input {
    text-align: center !important;
    flex: 1;
    margin: 0 5px;
    border-left: 0 !important;
    border-right: 0  !important;
  }
  

  .quantity-control .btn.btn-right {
    border-left: solid 1px hsl(0, 0%, 80%) !important;
}

.quantity-control .btn.btn-left {
  border-right: solid 1px hsl(0, 0%, 80%) !important;
}

/* Hide the spinner for number input fields */
.quantity-input::-webkit-outer-spin-button,
.quantity-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.quantity-input[type=number] {
  -moz-appearance: textfield; /* Firefox */
}

.quantity-field {
  line-height: 1.7;
  margin: 0 0 10px;
  overflow: hidden;
}

.auth-wrap .auth-col .quantity-field input[type=number] {
	padding: 0 15px;
	color: #373d54;
	background: #f8fafc;
	font-size: 4em !important;
	height: auto !important;
	border: 1px solid #e0e4f6;
	transition: all 0.2s;
	display: inline-block;
	margin: 0;
	vertical-align: middle;
  border-radius: 5px;
  box-sizing: border-box;
}